import React from "react";
import { IconButton, IconButtonProps } from "./ui/button";
import { BoxProps } from "./ui/box";
import { colors } from "src/constants";

export const IconComponent = React.forwardRef(({ icon, ...rest }, ref) => {
  if (!icon) {
    return null;
  }
  const Icon = icon;

  const size = {
    height: "24px",
    width: "24px",
    minHeight: "24px",
    minWidth: "24px",
  };
  const sx = rest.sx || {};
  const { height, width } = sx;

  if (height || width) {
    size.minHeight = height || size.height;
    size.minWidth = width || size.width;
  }
  return (
    <Icon
      ref={ref}
      {...rest}
      sx={{
        ...size,
        fill: colors.gray1,
        ...sx,
      }}
    />
  );
});

/*

export const IconButtonInGrayBox = React.forwardRef(
  (
    {
      icon,
      iconProps = {},
      ...rest
    }: IconButtonProps & { iconProps?: BoxProps },
    ref
  ) => {
    return (
      <IconButton {...rest}>
        <IconComponent
          icon={icon}
          {...iconProps}
          sx={{
            ...(iconProps.sx || {}),
          }}
        />
      </IconButton>
    );
  }
);
*/
