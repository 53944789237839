import type { Session } from "@supabase/supabase-js";
import { isCn, adminUserIds } from "client-server-shared/constants";
import { drawerWidth } from "./modules/themes/sytle-constants";
import { SubscriptionWithPrice } from "client-server-shared/types/payment";

export const collectionPageId = "collection-page";

export const mainPageWidth = `calc(100% - ${drawerWidth}px)`;

export { adminUserIds };

let markdownFileName = "content.md";
let custom = "custom";

if (isCn()) {
  custom = "自定义";
}
let session: Session | null = null;
let activePlan: SubscriptionWithPrice | null = null;

const setGlobalSession = (newSession: Session) => {
  session = newSession;
};

const isUserAdminGlobal = () => {
  if (session) {
    return adminUserIds.includes(session.user?.id || "");
  }
  return false;
};

const getGlobalSession = () => {
  return session;
};

const setGlobalActivePlan = (newActivePlan: SubscriptionWithPrice | null) => {
  activePlan = newActivePlan;
};
const getGlobalActivePlan = () => {
  return activePlan;
};

export const colors = {
  black1: "rgb(2, 8, 23)",
  white1: "white",
  textWhite1: "text.white",
  red1: "rgba(255, 28, 70, 0.9)",
  gray1: "rgb(100, 116, 139)",
  gray2: "rgb(226, 232, 240)",
  pink1: "rgba(255, 28, 70, 0.1)",
  error1: "rgb(248 113 113)",
  gray3: "#F7FAFC",
  pink2: "rgb(168 85 247)",
};

export {
  custom,
  setGlobalActivePlan,
  getGlobalActivePlan,
  getGlobalSession,
  setGlobalSession,
  isUserAdminGlobal,
};
