import { Box, BoxProps } from "components/ui/box";
import React from "react";

export const FullBackgroundWrapper = React.forwardRef(
  ({ children, sx = {}, ...rest }: BoxProps, ref) => {
    return (
      <Box
        {...rest}
        sx={{
          background:
            "linear-gradient(177.9deg, #111827 0%, #360F67 50%, #360F67 100%)",
          ...sx,
        }}
        ref={ref}
      >
        {children}
      </Box>
    );
  }
);
export const MainBackgroundWrapper = React.forwardRef(
  ({ children, dsiableBackground, sx = {}, ...rest }: BoxProps, ref) => {
    return (
      <Box
        {...rest}
        sx={{
          ...(dsiableBackground ? {} : { backgroundColor: "#181a1f" }),
          ...sx,
        }}
        ref={ref}
      >
        {children}
      </Box>
    );
  }
);

export const headerHeight = 68;
