import {
  serverRoutes,
  templateServerRouteBySlug,
} from "client-server-shared/config/routes";
import { siteConfig } from "client-server-shared/config/siteConfig";
import { LogoIconOnly, LogoWithBlackText } from "components/logo";
import { NextLink } from "components/next-link";
import { Avatar } from "components/ui/avatar";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Container } from "components/ui/container";
import { List, ListItem, ListSubHeader } from "components/ui/list/List";
import { Description, Typography } from "components/ui/Typography";
import { MainBackgroundWrapper } from "./shared";
import { MetaColorIcon } from "client-server-shared/icons/meta-color-icon";
import { TwitterColorIcon } from "client-server-shared/icons/twitter-color-icon";
import Affilate from "./affliate";
import { trackClickOnSocialMediaLink } from "utils/tracking/common";
import { useTranslation } from "components/translate";
import { isCn } from "client-server-shared/constants";
import { colors } from "src/constants";
import { Divider } from "components/ui/divider";
import { useRouter } from "next/router";
import { isLoginRoute } from "client-server-shared/utils/get-url";

interface ListSectionProps {
  headerText: string;
  list: { text: string; to: string }[];
}

const useData = () => {
  const { t } = useTranslation("common");

  const productLists = [
    /*
    {
      text: "Bulk Content Generator",
      to: serverRoutes.bulkContentCreation,
    },
    
    {
      text: t("Chat By Junia.ai"),
      to: serverRoutes.chat,
    },
    {
      text: t("Templates"),
      to: serverRoutes.templates,
    },
    */
    {
      text: "Free AI Tools",
      to: serverRoutes.tools,
    },
    {
      text: "Pricing",
      to: serverRoutes.pricing,
    },
    /*
    {
      text: t("Testimonials"),
      to: serverRoutes.testimonials,
    },
    */
  ].filter(Boolean);
  const resourcesList = [
    /*
    {
      text: t("Guides"),
      to: serverRoutes.docs,
    },
    */
    {
      text: "Blogs",
      to: serverRoutes.blog,
    },
    /*
    {
      text: t("Personalized Writing"),
      to: serverRoutes.styleMatch,
    },
    */
  ];

  const supportList = [
    {
      text: "Terms",
      to: serverRoutes.terms,
    },
    {
      text: "Privacy Policy",
      to: serverRoutes.privacyPolicy,
    },
  ];
  return { productLists, resourcesList, supportList };
};

const ListSection = ({ headerText, list }: ListSectionProps) => {
  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      subheader={
        <ListSubHeader
          disableGutters
          sx={{
            background: "none",
          }}
        >
          <Typography variant="body1" fontWeight={600} color={colors.black1}>
            {headerText}
          </Typography>
        </ListSubHeader>
      }
    >
      {list.map((item) => {
        return (
          <ListItem
            key={item.text}
            disableGutters
            className="with-hover-underline-effect-a"
            sx={{
              paddingTop: "4px",
              paddingBottom: "4px",
              letterSpacing: ".5px",
            }}
          >
            <Typography
              component={NextLink}
              sx={{
                textDecoration: "none",
                fontSize: "14px",
                fontWeight: 300,
              }}
              href={item.to}
              color={colors.black1}
            >
              {item.text}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

const FooterBottom = () => {
  return (
    <Box
      sameLine
      alignCenter
      marginTop={48}
      sx={{
        justifyContent: "center",
        columnGap: "48px",
      }}
    >
      <Typography color="text.white">@ 2024 WritingTools.ai</Typography>
      <Box>
        <IconButton
          component="a"
          rel="noopener noreferrer nofollow"
          href={siteConfig.links.twitter}
          target="__blank"
          onClick={() => {
            trackClickOnSocialMediaLink({
              type: "twitter",
            });
          }}
          aria-label="Twitter"
        >
          <Avatar>
            <TwitterColorIcon />
          </Avatar>
        </IconButton>
      </Box>
    </Box>
  );
};

const Footer = () => {
  const { productLists, resourcesList, supportList } = useData();
  const { t } = useTranslation("common");
  const router = useRouter();
  if (isLoginRoute(router.pathname)) {
    return null;
  }
  return (
    <MainBackgroundWrapper
      component="footer"
      sx={{
        backgroundColor: colors.white1,
      }}
    >
      <Divider />
      <Container
        maxWidth="false"
        sx={{
          maxWidth: "1400px",
          padding: {
            xs: "80px 30px",
            sm: "80px",
            md: "80px",
          },
        }}
      >
        <LogoWithBlackText width={170} />

        <Box
          sx={{
            display: "flex",
            rowGap: "24px",
            marginTop: "24px",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <Box
            component="section"
            sx={{
              display: "flex",
              flexGrow: 1,
              flexWrap: "wrap",
              rowGap: "48px",
              columnGap: "12px",
              justifyContent: {
                xs: "space-between",
                sm: "space-between",
                md: "space-between",
                lg: "space-between",
                xl: "space-between",
              },
            }}
          >
            <ListSection headerText={t("Product")} list={productLists} />

            <ListSection headerText={t("Resources")} list={resourcesList} />

            <ListSection headerText={t("Company")} list={supportList} />
          </Box>
        </Box>
        <Box
          sx={{
            margin: "24px 0",
          }}
        >
          <Divider />
        </Box>
        <Typography
          color={colors.black1}
          sx={{
            fontSize: "14px",
          }}
        >
          Copyright @ 2024 WritingTools.ai
        </Typography>
      </Container>
    </MainBackgroundWrapper>
  );
};

export default Footer;
