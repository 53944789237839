const variationsEnv = {
  dev: {
    proPlanYearly: ["price_1Pywjj2MmzUf4kWCoEnSRLDD"],
    proPlanMonthly: ["price_1Pywit2MmzUf4kWCPPft5b0r"],
  },
  prod: {
    proPlanYearly: ["price_1Pywjj2MmzUf4kWCoEnSRLDD"],
    proPlanMonthly: ["price_1Pywit2MmzUf4kWCPPft5b0r"],
  },
};
export const variationsPricesById: {
  proPlanYearly: string[];
  proPlanMonthly: string[];
} = variationsEnv[process.env.NEXT_PUBLIC_ENV_NAME];

const pricesEnv = {
  dev: {
    proPlanYearly: [
      "price_1Pywjj2MmzUf4kWCoEnSRLDD",
      ...variationsPricesById.proPlanYearly,
    ],
    proPlanMonthly: [
      "price_1Pywit2MmzUf4kWCPPft5b0r",
      ...variationsPricesById.proPlanMonthly,
    ],
    creatorPlanMonthlyNew: ["price_1Pywso2MmzUf4kWCUoHYAyEG"],
    creatorPlanYearlyNew: ["price_1PywtF2MmzUf4kWCehvTBimc"],
    creatorPlanMonthly: [],
    proMonthlyLegacy: [],
    creatorYearly: [],
    creatorYearlyLegacy: [],
    unlimitedMonthly: [],
    unlimitedMonthlyLegacy: [],
    unlimitedYearly: [],
    unlimitedYearlyLegacy: [],
    credits: ["price_1Pyx8T2MmzUf4kWCGHP04sB6"],
    workflows: [],
    trialFee: ["price_1PyxwS2MmzUf4kWC1rfj12jQ"],
    appSumoTier1: ["juniaai_tier1"],
    appSumoTier2: ["juniaai_tier2"],
    appSumoTier3: ["juniaai_tier3"],
    appSumoTier4: ["juniaai_tier4"],
  },
  prod: {
    proPlanYearly: [
      "price_1PywoU2MmzUf4kWCCWgNqTG0",
      ...variationsPricesById.proPlanYearly,
    ],
    proPlanMonthly: [
      "price_1PywoU2MmzUf4kWCB91VxoQH",
      ...variationsPricesById.proPlanMonthly,
    ],
    creatorPlanMonthlyNew: ["price_1PywtW2MmzUf4kWCaAsBlFCe"],
    creatorPlanYearlyNew: ["price_1PywtW2MmzUf4kWCLYj9NTUa"],
    creatorPlanMonthly: [],
    proMonthlyLegacy: [],
    creatorYearly: [],
    creatorYearlyLegacy: [],
    unlimitedMonthly: [],
    unlimitedMonthlyLegacy: [],
    unlimitedYearly: [],
    unlimitedYearlyLegacy: [],
    workflows: [],
    credits: ["price_1Pyx8Z2MmzUf4kWCgw9qhdoc"],
    trialFee: ["price_1Pyxvm2MmzUf4kWCSl5CcBus"],
    appSumoTier1: ["juniaai_tier1"],
    appSumoTier2: ["juniaai_tier2"],
    appSumoTier3: ["juniaai_tier3"],
    appSumoTier4: ["juniaai_tier4"],
  },
};

interface ById {
  proPlanYearly: string[];
  proPlanMonthly: string[];
  creatorPlanMonthlyNew: string[];
  creatorPlanYearlyNew: string[];
  creatorPlanMonthly: string[];
  creatorYearly: string[];
  creatorYearlyLegacy: string[];
  proMonthlyLegacy: string[];
  unlimitedMonthly: string[];
  unlimitedYearly: string[];
  unlimitedMonthlyLegacy: string[];
  unlimitedYearlyLegacy: string[];
  credits: string[];
  workflows: string[];
  trialFee: string[];
  appSumoTier1: string[];
  appSumoTier2: string[];
  appSumoTier3: string[];
  appSumoTier4: string[];
}

export const pricesById: ById = pricesEnv[process.env.NEXT_PUBLIC_ENV_NAME];
