export const cnUrl = "https://writingtools.ai";
export const enUrl = "https://writingtools.ai";

const siteConfig = {
  copyright: "",
  title: "#1 AI Writing Tool For Content Generation",
  description:
    "Writingtools.ai is the best AI writing tool for content generation. It helps you generate high-quality content in minutes.",
  email: "contact@writingtools.ai",
  logo: {
    url: "/logo.svg",
    alt: "AI writing tools",
  },
  whiteLogo: {
    url: "/logo-white.png",
    alt: "AI writing tools",
  },
  logoIcon: {
    url: "/logo-icon-only.png",
    alt: "AI writing tools",
  },
  logoWithBlackText: {
    url: "/logo-with-black-text.svg",
    alt: "AI writing tools",
  },
  favicon: "/favicon.png",
  openGraphImage: "/og.png",
  url: enUrl,
  locale: "en",
  alternateUrl: cnUrl,
  alternateHrefLang: "zh",
  name: "WritingTools.ai",
  links: {
    twitter: "https://twitter.com/writingtools_ai",
    facebook: "https://www.facebook.com",
    youtube: "https://youtube.com/writingtools.ai",
    linkedin: "https://linkedin.com",
  },
};

export { siteConfig };
