import MUITypography, {
  TypographyProps as MuiTypographyProps,
} from "@mui/material/Typography";
import { getStyleProps, passProps } from "modules/themes/styleUtils";
import React from "react";
import { colors } from "src/constants";

export type TypographyProps = MuiTypographyProps;
export const Typography = React.forwardRef((props: TypographyProps, ref) => {
  const { children, innerRef, ...rest } = props;
  return (
    <MUITypography
      ref={innerRef || ref}
      {...passProps(rest)}
      {...getStyleProps(rest)}
    >
      {children}
    </MUITypography>
  );
});

export const Description = React.forwardRef((props: TypographyProps, ref) => {
  const { children, innerRef, ...rest } = props;
  return (
    <Typography
      variant="body2"
      color={colors.gray1}
      ref={innerRef || ref}
      {...rest}
      sx={{
        fontSize: "14px",
        ...(rest.sx || {}),
      }}
    >
      {children}
    </Typography>
  );
});

Typography.displayName = "Typography";
