import { getURL } from "../utils/get-url";
import { siteConfig } from "./siteConfig";
import { languageToLocalePlain } from "../constants";
const dashboardRoutes = {
  dashboard: "/dashboard",
  templates: "/dashboard/tools",
  collections: "/dashboard/documents",
  customWorkflow: "/dashboard/custom-workflow",
  recipets: "/dashboard/recipets",
  settings: "/dashboard/settings",
  billing: "/dashboard/billing",
  postById: "/dashboard/documents/:collectionId/:postId",
  integrations: "/dashboard/integrations",
  chat: "/dashboard/chat",
  AIImage: "/dashboard/ai-image",
  AIImageRestorer: "/dashboard/ai-image?tab=restorer",
  voice: "/dashboard/brand-voice",
  infoBase: "/dashboard/brand-voice?tab=info-base",
  persona: "/dashboard/persona",
  workflows: "/dashboard/workflows",
  workflowBySlug: "/dashboard/workflows/:slug",
  workspace: "/workspace",
  pastWorkflows: "/workflows/all",
  bulkMode: "/dashboard/bulk-generate",
  folders: "/dashboard/folders",
  articleWriter: "/dashboard/articles/single",
  articleWriterHistory: "/dashboard/articles"
};

export const compareRoutes = {
  chatgpt: "/compare/junia-vs-chatgpt",
  filechat: "/compare/junia-vs-other-files-chat",
};

export const serverRoutes = {
  front: "/",
  login: "/login",
  register: "/register",
  about: "/about",
  blog: "/blog",
  templates: "/tools",
  art: "/art",
  chat: "/chat",
  imageRestore: "/image-restorer",
  editor: "/tools/ai-text-editor",
  features: "/features",
  payment: "/payment",
  security: "/security",
  cookiePolicy: "/cookie",
  privacyPolicy: "/privacy",
  terms: "/terms",
  useCases: "/use-cases",
  guides: "/guides",
  pricing: "/pricing",
  samples: "/public/samples",
  // carousel: "/react-multi-carousel",
  contact: "/contact-us",
  affiliate: "/affiliate",
  aiDetector: "/tools/ai-detector",
  musicGen: "/tools/music-generator",
  styleMatch: "/brand-voice",
  bulkContentCreation: "/bulk-content-creation",
  citation: "/tools/citation-generator",
  docs: "/docs",
  announcements: "/docs/announcements",
  blogImageGenerator: "/tools/blog-images",
  aiInternalLinking: "/tools/ai-internal-linking",
  aiKeywordResearch: "/tools/ai-keyword-research",
  author: "/team",
  testimonials: "/testimonials",
  modernSlaveryStatement: "/modern-slavery-statement",
  ediPolicy: "/edi-policy",
  accessibility: "/accessibility",
  codeOfEthics: "/code-of-ethics",
  editorialPolicy: "/editorial-policy",
  complaintsPolicy: "/complaints-policy",
  guestPosting: "/guest-posting-policy",
  tools: "/tools",
  bulkTranslate: "/tools/multi-language-bulk-translate",
  reabilityImprover: "/tools/readability-improver",
  textExpander: "/tools/text-expander",
  metaTitleGenerator: "/tools/meta-title-generator",
  metaDescriptionGenerator: "/tools/meta-description-generator",
  headlineGenerator: "/tools/headline-generator",
  paraphrasingTool: "/tools/paraphrasing-tool",
  textGenerator: "/tools/text-generator",
  promptGenerator: "/tools/prompt-generator",
  paragraphGenerator: "/tools/paragraph-generator",
  sentenceGenerator: "/tools/sentence-generator",
  humanizer: "/tools/humanizer",
  gptDirectory: "/gpts-directory",
  introduction: "/tools/introduction-generator",
  byPassAI: "/tools/bypass-ai-detection",
  sloganGenerator: "/tools/slogan-generator",
  prosAndCons: "/tools/pros-and-cons-generator",
  reworder: "/tools/reworder",
  textSimplifier: "/tools/text-simplifier",
  passiveToActiveVoice: "/tools/passive-to-active-voice",
  activeToPassiveVoice: "/tools/active-to-passive-voice",
  sentenceShortener: "/tools/sentence-shortener",
  bookIdea: "/tools/book-idea-generator",
  bookTitle: "/tools/book-title-generator",
  nicheIdeas: "/tools/niche-ideas-generator",
  valueProposition: "/tools/value-proposition-generator",
  proposal: "/tools/proposal-generator",
  resignationLetter: "/tools/resignation-letter-generator",
  callToActionGenerator: "/tools/call-to-action-generator",
  businessNameGenerator: "/tools/business-name-generator",
  hookGenerator: "/tools/hook-generator",
  acronymGenerator: "/tools/acronym-generator",
  conclusionGenerator: "/tools/conclusion-generator",
  grammerChecker: "/tools/grammar-checker",
  emailResponder: "/tools/email-responder",
  customerMessageResponder: "/tools/customer-message-generator",
  redditReply: "/tools/reddit-comment-generator",
  twitterReply: "/tools/twitter-reply-generator",
  autoblogging: "/autoblogging",
  appsumo: "/login/appsumo",
  ...compareRoutes,
};

export const serverRoutesConfig = {
  ...serverRoutes,
  authorBySlug: `/team/[yi]`,
  facebookAds: `${serverRoutes.templates}/facebook-ads-generator`,
  templateBySlug: `${serverRoutes.templates}/[slug]`,
  blogBySlug: `${serverRoutes.blog}/[slugOrLang]`,
  blogByLangAndSlug: `${serverRoutes.blog}/[slugOrLang]/[slug]`,
  featureBySlug: `${serverRoutes.features}/[slug]`,
  shareArticleRouteById: `/public/article/[id]`,
  docsBySlug: "/docs/[slug]",
  docsByCategory: "/docs/category/[slug]",
  wordpressIntegration: "/docs/wordpress-integration",
  webflowIntegration: "/docs/webflow-integration",
  shopifyIntegration: "/docs/shopify-integration",
};

export const blogRouteBySlug = (slug: string, language?: string) => {
  if (!language || language === "English") {
    return `${serverRoutes.blog}/${slug}`;
  }
  return `${serverRoutes.blog}/${languageToLocalePlain[language]}/${slug}`;
};

export const fromParams = "from";

export const priceIdParams = "plan";

export const buildFromParams = (from: string) => {
  return `${fromParams}=${encodeURIComponent(from)}`;
};

export const templateServerRouteBySlug = (slug: string) => {
  return `${serverRoutes.templates}/${slug}`;
};

export const workflowRouteBySlug = (slug: string) => {
  return `${dashboardRoutes.workflows}/${slug}`;
};

export const dashboardRoutesValues = Object.values(dashboardRoutes);

export const dashboardRouteConfig = {
  ...dashboardRoutes,
  appsumo: "/dashboard/appsumo",
  folderById: `${dashboardRoutes.folders}/:folderId`,
  collectionById: `${dashboardRoutes.collections}/:collectionId`,
  postById: `${dashboardRoutes.collections}/:collectionId/:postId`,
  templateById: `${dashboardRoutes.templates}/:templateId`,
  integrationByPlatformType: `${dashboardRoutes.integrations}/:platformType`,
  upgradeRoute: `${dashboardRoutes.billing}#pricing`,
  workflowById: `${dashboardRoutes.workflows}/:type/:id`,
  teamFeature: `${dashboardRoutes.settings}?tab=team`,
};

export const dashboardRouteValues = Object.values(dashboardRouteConfig);

export const serverRoutesValues = Object.values(serverRoutesConfig);

export const templateByIdRoute = (id: string) =>
  `${dashboardRouteConfig.templates}/${id}`;

export const collectionByIdRoute = (id: string) =>
  `${dashboardRouteConfig.collections}/${id}`;

export const folderByIdRoute = (id: string) =>
  `${dashboardRouteConfig.folders}/${id}`;

export const postByIdRoute = (collectionId: string, postId: string) =>
  `${dashboardRouteConfig.collections}/${collectionId}/${postId}`;

export const workflowByIdRoute = (type: string, id: string) =>
  `${dashboardRouteConfig.workflows}/${type}/${id}`;

export const integrationByPlatformType = (type: "contentful") =>
  `${dashboardRouteConfig.integrations}/${type}`;

export const emailResubscribeRouteByTokenId = (tokenId: string) =>
  `/email/resubscribe/${tokenId}`;

export const fullEmailUnsubscribeRouteByTokenId = (tokenId: string) =>
  getURL(`email/unsubscribe/${tokenId}`);

export const fullShareArticleRouteById = (id: string) => {
  return `${siteConfig.url}/public/article/${id}`;
};

export const docCategoryRouteBySlug = (slug: string) => {
  return `${serverRoutes.docs}/category/${slug}`;
};

export const docRouteBySlug = (slug: string) => {
  return `${serverRoutes.docs}/${slug}`;
};
